body,
html {
    height: 100%;
    margin: 0;
    font-family: 'Poppins', sans-serif !important;

}

#root {
    height: 100%;
    margin: 0;
    transition: 0.2s;
}

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #c0c4be #f4f4f4;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 4px;
}

*::-webkit-scrollbar-track {
    background: #fff;
}

*::-webkit-scrollbar-thumb {
    background-color: #c0c4be;
    border-radius: 20px;
    border: 0px solid #c0c4be;
}



p {
    font-size: 12px;
}

nav {
    flex: 1;
    text-align: right;
}

ul li {
    list-style: none;
    color: black;
    padding: 20px 0 20px 0;
    display: inline-block;
    font-weight: 500;
    margin: 0 0;
    font-size: 12px;
    cursor: pointer;
    position: relative;
}

nav ul li button {
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    color: white;
    border: none;
    background-color: transparent;
}

nav ul li button:hover {
    color: #F59E0C;
    cursor: pointer;
}

nav ul li button:after {
    color: #F59E0C;
    cursor: pointer;
}

.MuiOutlinedInput-root {
    background: #f4f4f4;
}

#active {
    color: #5EA73C;
    cursor: pointer;
    font-weight: 600;
    background: #f4f4f4 url("../icons/menu-curve-rect.svg") no-repeat;
    background-size: contain;
    transition: 0.2s;
}

#active > span > .active_color > path {
    fill: #5EA73C;
}

.my-location-button {
    border: 0;
    border-radius: 2px;
    margin: 10px;
    overflow: hidden;
    height: 40px;
    cursor: pointer;
}

.my-location-button:hover {
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
}