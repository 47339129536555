.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}

.modal.enter-done {
    opacity: 1;
    pointer-events: visible;
    z-index: 1000;
}

.modal.exit {
    opacity: 0;
}

.modal-content {
    width: 600px;
    height: 500px;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    transform: translateY(300px);
}

.modal.enter-done .modal-content {
    transform: translateY(0);
}

.modal.exit .modal-content {
    transform: translateY(300px);
}

.modal-header,
.modal-footer {
    padding: 10px;
}

.modal-title {
    margin: 0;
}

